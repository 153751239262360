import { Link } from 'gatsby'
import React from 'react'
import logo from '../../static/images/logo.svg'
import { useStaticQuery, graphql } from 'gatsby'

const Header = () => {
  const {
    site: { siteMetadata }
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            shopifyUrl
          }
        }
      }
    `
  )

  return (
    <header>
      <div className={'container'}>
        <div className={'top-menu'}>
          <div className={'logo'}>
            <Link to="/" title={'Loop: Personalized Recommendations'}>
              <img alt={'Logo'} src={logo} />
            </Link>
          </div>

          <div className={'get-started'}>
            <Link to="#pricing" title={'Pricing'}>
              Pricing
            </Link>
            <Link to="#testimonials" title={'Reviews'}>
              Reviews
            </Link>
            <Link to="#customers" title={'Customers'}>
              Customers
            </Link>
            <Link to="/partners" title={'Partners'}>
              Partners
            </Link>
            <Link to="/integrations" title={'Integrations'}>
              Integrations
            </Link>
            {false && (
              <Link to="/about" title={'About Us'}>
                About
              </Link>
            )}
            {false && <a href={'/blog'}>Blog</a>}
            <a
              href={siteMetadata.shopifyUrl}
              className={'get-started__solid-link'}
            >
              Get Started
            </a>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
