import { Link } from 'gatsby'
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import logo from '../../static/images/logo.svg'
import iconTwitter from '../../static/images/icon-twitter.svg'
import iconFacebook from '../../static/images/icon-facebook.svg'

const Footer = () => {
  const {
    site: { siteMetadata }
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            supportEmail
            feedbackEmail
            twitterUrl
            facebookUrl
            footerCompanyDescription
          }
        }
      }
    `
  )

  return (
    <footer>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-5'}>
            <div className={'widget__item'}>
              <div className={'logo'}>
                <Link to="/" title={'Personalized Recommendations'}>
                  <img alt={'Logo'} src={logo} />
                </Link>
              </div>

              <div className={'about'}>
                <p>{siteMetadata.footerCompanyDescription}</p>
              </div>
            </div>
          </div>

          <div className={'col-2'}>
            <div className={'widget__item'}>
              <ul className={'links'}>
                <h4>Loop</h4>
                <ul>
                  <li>
                    <Link to="/integrations" title={'Integrations'}>
                      Integrations
                    </Link>
                  </li>
                  {false && (
                    <li>
                      <Link to="/about" title={'About Us'}>
                        About
                      </Link>
                    </li>
                  )}
                  {false && (
                    <li>
                      <a href={'/blog'}>Blog</a>
                    </li>
                  )}
                  <li>
                    <a href={'/partners'}>Partners</a>
                  </li>
                  <li>
                    <a
                      className={'links__special'}
                      href={`mailto:${siteMetadata.feedbackEmail}`}
                      title={'We look forward to receiving your great feedback'}
                    >
                      Feedback
                    </a>
                  </li>
                </ul>
              </ul>
            </div>
          </div>

          <div className={'col-2'}>
            <div className={'widget__item'}>
              <div className={'links'}>
                <h4>Support</h4>
                <ul>
                  <li>
                    <a
                      href={`mailto:${siteMetadata.supportEmail}`}
                      title={'Contact Us'}
                    >
                      Contact
                    </a>
                  </li>
                  <li>
                    <Link to="/privacy" title={'Privacy Policy'}>
                      Privacy
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms" title={'Terms Of Use'}>
                      Terms Of Use
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className={'col-3'}>
            <div className={'widget__item'}>
              <div className={'social'}>
                <a
                  href={siteMetadata.twitterUrl}
                  target={'_blank'}
                  title={'Twitter'}
                >
                  <img alt={'Twitter'} src={iconTwitter} />
                </a>
                <a
                  href={siteMetadata.facebookUrl}
                  target={'_blank'}
                  title={'Facebook'}
                >
                  <img alt={'Facebook'} src={iconFacebook} />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={'copyright'}>
          <p>
            Copyright {new Date().getFullYear()}, {` `}{' '}
            <a href="https://loopclub.io" title={'Loopclub'}>
              Loopclub
            </a>
            . All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
